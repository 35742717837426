@use 'sass:math';
@import '../../styles/index';

$additional-top-shift: 3.75rem;
$default-skew-height: 322;
$default-skew-width: 1440;

$skew-point-position: min(
  #{math.div($default-skew-height, $default-skew-width) * 100}vw,
  #{$default-skew-width}px
);

.base {
  background-color: $color-surrogate--05;
  height: calc(100% - #{$additional-top-shift});
  inset: 0;
  margin-top: $additional-top-shift * 2;
  position: absolute;
  width: 100%;

  @include media-from(sm) {
    margin-top: $additional-top-shift;
  }

  &.bottom {
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% $skew-point-position);
  }

  &.top {
    clip-path: polygon(0% $skew-point-position, 100% 0%, 100% 100%, 0% 100%);
  }

  &.left {
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, $skew-point-position 100%);
  }

  &.right {
    clip-path: polygon(0% 0%, $skew-point-position 0%, 100% 100%, 0% 100%);
  }
}
